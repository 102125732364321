export const assessmentPortalURL = {
  development: "https://assessment-portal-development.herokuapp.com/redirect",
  staging: "https://assessment-portal-staging.herokuapp.com/redirect",
  production: "https://mlu-portal.mdhs.unimelb.edu.au/redirect",
};

export const contentBuilderURL = {
  development: "https://content-builder-development.herokuapp.com/redirect",
  staging: "https://content-builder-staging.herokuapp.com/redirect",
  production: "https://mlu-builder.mdhs.unimelb.edu.au/redirect",
};

export const unimelbWebsiteURL = "https://www.unimelb.edu.au/";

export const mobileLearningWebsiteURL =
  "https://medicine.unimelb.edu.au/about/mobile-learning-unit";
